import GithubIcon from '../../assets/github.png';
import PortfolioIcon from '../../assets/portfolio.png';
import LinkedInIcon from '../../assets/linkedin.png';
import ResumeIcon from '../../assets/resume.png';

const data = {
  firstName: 'Eric',
  middleName: '',
  lastName: 'Mong',
  email: 'm.changhsi@gmail.com',
  phone: '+65 96308905',
  title: 'Software Developer',
  organization: '',
  profilePicture: 'https://avatars.githubusercontent.com/u/32842582?v=4',
  bio: 'A software developer specialized in frontend development',
  links: [
    {
      logo: ResumeIcon,
      text: 'Resume',
      link: 'https://drive.google.com/file/d/1EcOHNKK4QSbJf3TDGbzIsMVKTYOYtd4E/view',
    },
    {
      logo: GithubIcon,
      text: 'Github',
      link: 'https://github.com/mongchanghsi',
    },
    {
      logo: PortfolioIcon,
      text: 'Portfolio',
      link: 'https://www.ericmong.com/',
    },
    {
      logo: LinkedInIcon,
      text: 'LinkedIn',
      link: 'https://www.linkedin.com/in/ericmongchanghsi/',
    },
  ],
};

export default data;
